



export enum Currency {
  CZK = 'CZK',
  EUR = 'EUR',
  HUF = 'HUF',
};

export const CURRENCIES: Currency[] = Object.values(Currency);
