import { Injectable } from '@angular/core';
import { fromEvent, mapTo, merge } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  private online$ = fromEvent(window, 'online').pipe(mapTo(true));
  private offline$ = fromEvent(window, 'offline').pipe(mapTo(false));

  public isOnline$ = merge(this.online$, this.offline$);

  constructor() { }
}
