<app-modal-header
  [highlighted]="true"
  (close)="close()">
  <span slot="breadcrumb">
    {{'admin.form-field-settings-modal.title' | translate }}
  </span>
  <span slot="title">
    <app-blank-input [FC]="form.controls.label" [placeholder]="'admin.form-field-settings-modal.item-label' | translate"></app-blank-input>
  </span>
</app-modal-header>


<div [formGroup]="form" class="modal-body align-items-center" [class.loading-box]="loading">

  <div class="col-12">
    <p-dropdown
      formControlName="dataType"
      [options]="dataTypesOptions"
      optionLabel="label"
      optionValue="value"
      placeholder="{{'admin.form-field-settings-modal.selectdatatype' | translate }}"
      class="w-full"
      styleClass="w-full">
    </p-dropdown>
  </div>

  <div class="col-12 flex column-gap-2 my-2" *ngIf="dataTypeFC.value !== DataType.READONLY_TEXT">
    <p-inputSwitch class="flex align-self-center" formControlName="required" />

    <span class="flex align-self-center" [class.text-primary]="requiredFC.value">
      {{ requiredFC.value ? ('admin.form-field-settings-modal.required' | translate) : ('admin.form-field-settings-modal.optional' | translate) }}
    </span>
  </div>

  <div class="col-12">
    <span class="p-float-label">
      <input id="description" formControlName="description" type="text" pInputText class="w-full">
      <label for="description">{{'admin.form-field-settings-modal.shortdescription' | translate }}</label>
    </span>
  </div>

  <div *ngIf="dataTypeFC.value === DataType.CHECKBOX || dataTypeFC.value === DataType.TRI_STATE_CHECKBOX || dataTypeFC.value === DataType.CONSENT" class="col-12" [ngbTooltip]="showUserDataDisabledTooltip ? ('admin.form-field-settings-modal.user-data-disabled.tooltip' | translate) : null" container="body">
    <span class="p-float-label">
      <input id="link" formControlName="link" type="text" pInputText class="w-full">
      <label for="link">{{'admin.form-field-settings-modal.url' | translate }}</label>
    </span>
  </div>

  <div *ngIf="dataTypeFC.value === DataType.MULTISELECT" class="col-12" [ngbTooltip]="showUserDataDisabledTooltip ? ('admin.form-field-settings-modal.user-data-disabled.tooltip' | translate) : null" container="body">
    <small class="text-bluegray-400">{{'DataType.MULTISELECT.description' | translate }}</small>
    <textarea rows="3" pInputTextarea [autoResize]="true" formControlName="multiSelectItems" class="w-full"></textarea>
  </div>

  <div *ngIf="selectionLimitFC" class="col-12" [ngbTooltip]="showUserDataDisabledTooltip ? ('admin.form-field-settings-modal.user-data-disabled.tooltip' | translate) : null" container="body">
    <small class="text-bluegray-400">{{'admin.form-field-settings-modal.selection-limit' | translate }}</small>
    <p-selectButton [options]="selectionLimitOptions" [allowEmpty]="false" formControlName="selectionLimit" optionLabel="label" optionValue="value"></p-selectButton>
  </div>

  <div class="col-12 flex gap-2" *ngIf="otherOptionFC" [ngbTooltip]="showUserDataDisabledTooltip ? ('admin.form-field-settings-modal.user-data-disabled.tooltip' | translate) : null" container="body">
    <p-checkbox formControlName="otherOption" [binary]="true" inputId="otherOption"></p-checkbox>
    <label for="otherOption">
      <span>{{'admin.form-field-settings-modal.other-option' | translate }}</span>
    </label>
  </div>

  <div *ngIf="dataTypeFC.value === DataType.SCROLLING_TEXTAREA" class="col-12" [ngbTooltip]="showUserDataDisabledTooltip ? ('admin.form-field-settings-modal.user-data-disabled.tooltip' | translate) : null" container="body">
    <small class="text-bluegray-400">{{'DataType.SCROLLING_TEXTAREA.description' | translate }}</small>
    <p-editor #ed formControlName="scrollingContent" placeholder="{{'DataType.SCROLLING_TEXTAREA.placeholder' | translate }}" [modules]="{clipboard:{matchVisual:false}}" class="-mt-2" [style]="{'height':'8rem'}">
      <ng-template pTemplate="header">
        <span class="ql-formats">
          <button type="button" class="ql-bold" aria-label="Bold"></button>
          <button type="button" class="ql-italic" aria-label="Italic"></button>
          <button type="button" class="ql-underline" aria-label="Underline"></button>
          <button type="button" class="ql-strike" aria-label="Strike"></button>
        </span>
        <span class="ql-formats">
          <button class="ql-list" value="ordered"></button>
          <button class="ql-list" value="bullet"></button>
        </span>
        <span class="ql-formats">
          <button aria-label="Link" class="ql-link"></button>
        </span>
        <span class="ql-formats">
          <button (click)="ed.getQuill().removeFormat(ed.getQuill().getSelection().index,ed.getQuill().getSelection().length)" class="cust-clear" title="{{'admin.wysiwyg.clearFormating' | translate }}"><i class="pi pi-eraser text-lg font-medium text-color-secondary" style="margin-top: 0.102rem;"></i></button>
        </span>
      </ng-template>
    </p-editor>
  </div>

  <div *ngIf="dataTypeFC.value === DataType.READONLY_TEXT" class="col-12">
    <small class="text-bluegray-400">{{'DataType.READONLY_TEXT.description' | translate }}</small>
    <p-editor #ed_rc formControlName="readonlyContent" placeholder="{{'DataType.READONLY_TEXT.placeholder' | translate }}" [modules]="{clipboard:{matchVisual:false}}" class="-mt-2" [style]="{'height':'8rem'}">
      <ng-template pTemplate="header">
        <span class="ql-formats">
          <button type="button" class="ql-bold" aria-label="Bold"></button>
          <button type="button" class="ql-italic" aria-label="Italic"></button>
          <button type="button" class="ql-underline" aria-label="Underline"></button>
          <button type="button" class="ql-strike" aria-label="Strike"></button>
        </span>
        <!-- <span class="ql-formats">
          <select class="ql-color"></select>
          <select class="ql-background"></select>
        </span> -->
        <span class="ql-formats">
          <button class="ql-list" value="ordered"></button>
          <button class="ql-list" value="bullet"></button>
        </span>
        <span class="ql-formats">
          <button aria-label="Link" class="ql-link"></button>
        </span>
        <span class="ql-formats">
          <button (click)="ed_rc.getQuill().removeFormat(ed_rc.getQuill().getSelection().index,ed_rc.getQuill().getSelection().length)" class="cust-clear" title="{{'admin.wysiwyg.clearFormating' | translate }}"><i class="pi pi-eraser text-lg font-medium text-color-secondary" style="margin-top: 0.102rem;"></i></button>
        </span>
      </ng-template>
    </p-editor>
  </div>

  <div class="col-12 flex gap-2" *ngIf="useAsPrivacyPolicyFC">
    <p-checkbox formControlName="useAsPrivacyPolicy" [binary]="true" inputId="useAsPrivacyPolicy"></p-checkbox>
    <label for="useAsPrivacyPolicy">
      <span>{{'admin.form-field-settings-modal.useAsPrivacyPolicy' | translate }}</span>
    </label>
  </div>

  <div class="col-12 flex gap-2" *ngIf="useAsTOSFC">
    <p-checkbox formControlName="useAsTOS" [binary]="true" inputId="useAsTOS"></p-checkbox>
    <label for="useAsTOS">
      <span>{{'admin.form-field-settings-modal.useAsTOS' | translate }}</span>
    </label>
  </div>

  <div class="col-12 flex gap-2" *ngIf="useAsBillingInfoFC">
    <p-checkbox formControlName="useAsBillingInfo" [binary]="true" inputId="useAsBillingInfo"></p-checkbox>
    <label for="useAsBillingInfo">
      <span>{{'admin.form-field-settings-modal.useAsBillingInfo' | translate }}</span>
    </label>
  </div>

  @if (dataTypeFC.value !== DataType.TITLES) {
    <p-divider></p-divider>

    <ng-container *ngIf="dataTypeFC.value === DataType.DATE || dataTypeFC.value === DataType.BIRTHDATE">
      <span>{{'admin.form-field-settings-modal.conditions' | translate }}</span>

      <!-- TODO: do it dynamically -->
      <div class="p-inputgroup col-12">
        <span class="p-inputgroup-addon">{{ AcceptationRequirementTemplateCodeName.get(acceptationRequirementsFA.controls[0].controls.templateCode.value) }}</span>
        <p-inputNumber
          appNumberInput
          placeholder="{{'admin.form-field-settings-modal.yearsCount' | translate }}"
          mode="decimal"
          [minFractionDigits]="0"
          [maxFractionDigits]="5"
          [formControl]="acceptationRequirementsFA.controls[0].controls.value"
        />
        <span class="p-inputgroup-addon"><p-checkbox [binary]="true" [formControl]="acceptationRequirementsFA.controls[0].controls.active"></p-checkbox></span>
      </div>

      <div class="p-inputgroup col-12">
        <span class="p-inputgroup-addon">{{ AcceptationRequirementTemplateCodeName.get(acceptationRequirementsFA.controls[1].controls.templateCode.value) }}</span>
        <p-inputNumber
          appNumberInput
          placeholder="{{'admin.form-field-settings-modal.yearsCount' | translate }}"
          mode="decimal"
          [minFractionDigits]="0"
          [maxFractionDigits]="5"
          [formControl]="acceptationRequirementsFA.controls[1].controls.value"
        />
        <span class="p-inputgroup-addon"><p-checkbox [binary]="true" [formControl]="acceptationRequirementsFA.controls[1].controls.active"></p-checkbox></span>
      </div>

      <p-divider></p-divider>

    </ng-container>

    <div class="col-12 py-0">
      <span>{{'admin.form-field-settings-modal.collecting-data-title' | translate }}</span>
    </div>

    <div class="col-12" [ngbTooltip]="showUserDataDisabledTooltip ? ('admin.form-field-settings-modal.user-data-disabled.tooltip' | translate) : null" container="body">
      <small class="text-bluegray-400">{{'admin.form-field-settings-modal.itemtitle' | translate }}</small>
      <input type="text" pInputText formControlName="title" class="w-full"/>
    </div>
    <small *ngIf="form.controls.title.hasError('title')" class="pl-2 -mt-2 p-error">{{'admin.form-field-settings-modal.alreadyexists' | translate }}</small>

    <div class="col-12">
      <small class="text-bluegray-400">{{'admin.form-field-settings-modal.collecting-data-option' | translate }}</small>
      <label
        for="isUserData-true"
        class="surface-border border-1 p-3 mb-0 surface-0 flex align-items-center gap-3 cursor-pointer border-round-top"
      >
        <p-radioButton inputId="isUserData-true" formControlName="isUserData" (onClick)="onUserDataSelect(true)" [value]="true"></p-radioButton>
        <div class="flex flex-column">
          <span>{{ 'admin.form-field-settings-modal.includeUserData.label' | translate }}</span>
          <small class="text-bluegray-400">{{ 'admin.form-field-settings-modal.includeUserData.description' | translate }}</small>
        </div>
      </label>

      <label
        for="isUserData-false"
        class="surface-border border-1 p-3 mb-0 surface-0 flex align-items-center gap-3 cursor-pointer border-round-bottom border-top-none"
      >
        <p-radioButton inputId="isUserData-false" formControlName="isUserData" (onClick)="onUserDataSelect(false)" [value]="false"></p-radioButton>
        <div class="flex flex-column">
          <span>{{ 'admin.form-field-settings-modal.reservationData.label' | translate }}</span>
          <small class="text-bluegray-400">{{ 'admin.form-field-settings-modal.reservationData.description' | translate }}</small>
        </div>
      </label>
    </div>

    <div *ngIf="form.controls.isUserData.value" class="col-12 flex justify-content-between align-items-center" [ngbTooltip]="showUserDataDisabledTooltip ? ('admin.form-field-settings-modal.user-data-disabled.tooltip' | translate) : null" container="body">
      <span>{{'admin.form-field-settings-modal.validityTime' | translate }}</span>
      <p-inputNumber
        appNumberInput
        placeholder="{{'admin.form-field-settings-modal.daycount' | translate }}"
        [formControl]="form.controls.expirationDays"
        inputStyleClass="input-align-right"
        [min]="1"
        [suffix]="' ' + (daysString | translate)"
      />
    </div>
  }
</div>

<div class="modal-footer">
  <button
    class="w-full"
    [loading]="saving"
    [disabled]="saving"
    (click)="onSubmit()"
    pButton
    label="{{'admin.form-field-settings-modal.save' | translate }}">
  </button>
</div>