import { Component, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { checkEnumMaps } from './shared/enums/enum-map-checker';
import { AppService } from './shared/services/app.service';
import { AuthService } from './shared/services/auth.service';
import { UserStoreService } from './shared/services/store/user-store.service';
import { SystemService } from './shared/services/system.service';
import { I18nService } from './shared/services/i18n.service';
import { Subscription } from 'rxjs';
import { ModalService } from './shared/services/modal.service';
import { NewAppVersionModalComponent } from './shared/modals/new-app-version-modal/new-app-version-modal.component';
import { NavigationService } from './shared/services/navigation.service';
import { UserPreferencesStoreService } from './shared/services/store/user-preferences-store.service';

export const NEW_APP_VERSION_CHANNEL = 'lektory-new-app-version';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  routeLoading: boolean = true;
  routeWaitingForDeactivationCheck: boolean = false;

  subs: Subscription[] = [];

  newVersionBroadcastChannel = new BroadcastChannel(NEW_APP_VERSION_CHANNEL);

  constructor(
    public userStore: UserStoreService, // keep
    private authService: AuthService, // keep,
    private appService: AppService, // keep
    private i18nService: I18nService, // keep
    private userPreferencesStore: UserPreferencesStoreService, // keep
    private router: Router,
    public sysService: SystemService,
    private modalService: ModalService,
    private navService: NavigationService
  ) {
    this.subs.push(
      this.router.events.subscribe((event) => {
        this.navigationInterceptor(event);
      }),

      this.navService.waitingForDeactivationCheck$.subscribe(b => {
        this.routeWaitingForDeactivationCheck = b;
      }),

      this.sysService.newAppVersion$.subscribe((b) => {
        if (b) {
          this.sysService.newAppVersion$.next(false);
          const ref = this.modalService.openNewAppVersionModal(NewAppVersionModalComponent);
          ref.result.then((r) => {
            this.newVersionBroadcastChannel.postMessage(this.sysService.appId);
            this.onNewAppVersionReload();
          });
        }
      })
    );

    // reaload all tabs
    this.newVersionBroadcastChannel.onmessage = (e) => {
      const appId = e.data;
      if (appId !== this.sysService.appId) {
        this.onNewAppVersionReload(); 
      }
    };

    checkEnumMaps();
  }

  ngOnInit(): void {
    const isInIframe = this.sysService.isInIframe();
    // if is in iframe, set html and body height to 100%
    if (isInIframe) {
      document.body.style.height = '100%';
      document.documentElement.style.height = '100%';
    }

  }

  private onNewAppVersionReload() {
    window.location.reload();
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: Event): void {
    if (event instanceof NavigationStart) {
      this.routeLoading = true;
    }
    if (event instanceof NavigationEnd) {
      this.routeLoading = false;
    }
    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.routeLoading = false;
    }
    if (event instanceof NavigationError) {
      this.routeLoading = false;
    }
  }

  get showRouteLoading(): boolean {
    return this.routeLoading && !this.routeWaitingForDeactivationCheck;
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}

declare global {
  interface Array<T> {
    sum(this: Array<number>): number;
  }
}
if (!Array.prototype.sum) {
  Array.prototype.sum = function<T>(this: Array<number>): number {
    return this.reduce((prev, curr) => prev += curr, 0);
  }
}
