import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-blank-input',
  standalone: true,
  imports: [
    CommonModule, ReactiveFormsModule,
  ],
  templateUrl: './blank-input.component.html',
  styleUrls: ['./blank-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BlankInputComponent implements AfterViewInit, OnDestroy {

  @ViewChild('input') input: ElementRef | undefined;
  @ViewChild('spanElement') spanElement: ElementRef | undefined;

  @Input() FC: FormControl<any> | null = null;
  @Input() placeholder: string | null = null;
  @Input() autofocus: boolean = false;
  @Input() type: string = 'text';
  @Input() inputClass: string | null = null;

  defaultInputWidth = 15;
  inputWidth = this.defaultInputWidth;

  subs: Subscription[] = [];

  constructor(
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {

    if (this.FC) {
      this.subs.push(
        this.FC.valueChanges.subscribe(() => {
          this.resizeInput();
        })
      )
    }

    // number type input emits string values, this will convert them to numbers
    if (this.type === 'number') {
      this.subs.push(
        this.FC?.valueChanges.subscribe(val => {
          if (val === null) return;
          else if (typeof val === 'string') {
            if (val.length) {
              const number = Number(val);
              if (!isNaN(number)) {
                this.FC?.setValue(number, { emitEvent: false });
              }
            } else {
              this.FC?.setValue(null, { emitEvent: false });
            }
          }
        }) ?? Subscription.EMPTY
      )
    }

  }

  ngAfterViewInit(): void {
    this.resizeInput()

    if (this.autofocus) this.focusInput();
  }

  public focusInput() {
    this.input?.nativeElement.focus();
  }

  resizeInput() {
    this.cdr.detectChanges();

    if (this.input?.nativeElement?.style) {
      this.input.nativeElement.style.width = `${this.defaultInputWidth}px`;
    }
    this.inputWidth = this.input?.nativeElement.value ? (this.input?.nativeElement.scrollWidth) : (this.spanElement?.nativeElement.offsetWidth + 3);

    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
