import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NetworkService } from '../../services/network.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-no-internet-bar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './no-internet-bar.component.html',
  styleUrls: ['./no-internet-bar.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        'max-height': '100px', 'opacity': '1', 'visibility': 'visible'
      })),
      state('out', style({
        'max-height': '0px', 'opacity': '0', 'visibility': 'hidden'
      })),
      transition('in => out', [
        animate('400ms ease-in-out')
      ]),
      transition('out => in', [
        animate('400ms ease-in-out')
      ])
    ])
  ]
})
export class NoInternetBarComponent {
  isConnected: boolean | undefined;
  isWelcome: boolean = false;
  animationState = 'out';

  constructor(private networkService: NetworkService) {
    this.isConnected = navigator.onLine;
    this.animationState = this.isConnected ? 'out' : 'in';
    this.networkService.isOnline$.subscribe(isOnline => {
      if (!this.isConnected && isOnline) {
        this.isWelcome = true;
        this.animationState = 'in';
        setTimeout(() => {
          this.animationState = 'out';
        }, 3000);
      } else if (this.isConnected && !isOnline) {
        this.isWelcome = false;
        this.animationState = 'in';
      }
      this.isConnected = isOnline;
    });
  }
}
